import React, { useState, useRef } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { ExpandMore, ExpandLess, Delete } from "@material-ui/icons";
import html2pdf from "html2pdf.js";
import LoadingButton from "../../../loadingButton";
import dealmemo_cover from "../../../../../../img/dealmemo-cover.png";
import favicon from "../../../../../../img/favicon.png";
import logo from "../../../../../../pitchboxStartup/assets/brand-logo.png";
import axios from "axios";
import "./dealMemo.css";
import { FullScreenModal } from "../../../../../../live/components/popups";

const stripePromise = loadStripe(
  "pk_test_51PKFGnSDXKl7WllCddjohJSMsFzkKi2q6GydRGAfuZ6Ag3B3Myu0tqcMxwr9HfVBKU6weqkwwOEvJsU5v2LoKMhL00mwTBn1vz",
);

const scoreToColor = (score) => {
  const colors = {
    1: "#E12B02",
    2: "#E16D02",
    3: "#F48604",
    4: "#F4A837",
    5: "#FAD051",
    6: "#F6E836",
    7: "#F7FA37",
    8: "#E8F64D",
    9: "#C1FF72",
    10: "#7ED957",
  };
  return colors[Math.round(score)] || colors[5];
};

const scoreToDescription = (score) => {
  const descriptions = {
    1: "Very Poor",
    2: "Poor",
    3: "Below Average",
    4: "Fair",
    5: "Average",
    6: "Above Average",
    7: "Good",
    8: "Very Good",
    9: "Excellent",
    10: "Outstanding",
  };
  return descriptions[Math.round(score)] || descriptions[5];
};

const Footer = ({ textColor = "#fff", pageNumber }) => {
  return (
    <div className="deal-memo-footer">
      <div className="deal-memo-footer__header">
        <h2 style={{ color: textColor }}>Investment Memo</h2>
        <div
          className="deal-memo-footer__divider"
          style={{ backgroundColor: textColor }}
        ></div>
      </div>
      <div className="deal-memo-footer__info">
        <img
          src={favicon}
          alt="Favicon"
          className="deal-memo-footer__favicon"
        />
        <div
          className="deal-memo-footer__vertical-divider"
          style={{ backgroundColor: textColor }}
        ></div>
        <h2
          className="deal-memo-footer__page-number"
          style={{ color: textColor }}
        >
          {pageNumber}
        </h2>
      </div>
    </div>
  );
};

const ReportOverview = ({ dealMemo, dateCreated }) => {
  if (!dealMemo) return null;

  return (
    <div className="deal-memo-report-overview">
      <div className="deal-memo-report-overview__container">
        <div className="deal-memo-report-overview__header">
          <div className="deal-memo-report-overview__title-section">
            <p className="deal-memo-report-overview__title">Investment Memo</p>
            <p className="deal-memo-report-overview__subtitle">
              POWERED BY{" "}
              <span style={{ fontWeight: "bold" }}>DILIGENCEGPT v1.1</span>
            </p>
          </div>
          <div className="deal-memo-report-overview__logo-section">
            <img
              src={logo}
              alt="StartupFuel Logo"
              className="deal-memo-report-overview__logo"
            />
            <p className="deal-memo-report-overview__agency">
              Venture Ratings Agency
            </p>
          </div>
        </div>
        <div className="deal-memo-report-overview__company-info">
          <p className="deal-memo-report-overview__company-name">
            {dealMemo.companyName}
          </p>
          <p className="deal-memo-report-overview__date">
            Date-created: {dateCreated}
          </p>
        </div>
        <hr className="deal-memo-report-overview__divider" />
        <div className="deal-memo-report-overview__report-type">
          <p className="deal-memo-report-overview__report-type-text">
            Equity Report
          </p>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "30px",
            flex: 1,
          }}
        >
          <p style={{ fontSize: "18px", fontWeight: "bold", margin: 0 }}>
            COMPANY OVERALL
          </p>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* Color scale boxes */}
            {[...Array(10)].map((_, i) => (
              <div
                key={i}
                style={{
                  width: "40px",
                  height: "40px",
                  backgroundColor: scoreToColor(i + 1),
                }}
              />
            ))}

            {/* Score indicator */}
            <div
              style={{
                position: "absolute",
                left: `${dealMemo.overallScore * 40 - 2}px`,
                top: "-20px",
                width: "4px",
                height: "60px",
                backgroundColor: "#000",
              }}
            ></div>

            {/* Score box */}
            <div
              style={{
                position: "absolute",
                left: `${dealMemo.overallScore * 40 - 50}px`,
                top: "-70px",
                padding: "8px 12px",
                backgroundColor: "#fff",
                color: "#000",
                border: "4px solid #000",
                borderRadius: "4px",
                whiteSpace: "nowrap",
              }}
            >
              <p style={{ margin: 0, fontSize: "18px" }}>
                <span style={{ fontWeight: "bold" }}>
                  {dealMemo.overallScore}
                </span>{" "}
                - {scoreToDescription(dealMemo.overallScore)}
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            textAlign: "center",
            fontSize: "18px",
            lineHeight: "1.5",
            marginTop: "40px",
          }}
        >
          {dealMemo.summary}
        </div>
        <div style={{ padding: "40px" }}>
          {dealMemo?.categories?.map((category) => (
            <div
              key={category.name}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <div
                style={{ width: "100px", fontWeight: "bold", fontSize: "18px" }}
              >
                {category.name}
              </div>
              <div
                style={{ flex: 1, display: "flex", justifyContent: "center" }}
              >
                <div
                  style={{ display: "flex", height: "20px", width: "400px" }}
                >
                  {[...Array(10)].map((_, i) => (
                    <div
                      key={i}
                      style={{
                        width: "40px",
                        height: "20px",
                        backgroundColor:
                          i < category.score
                            ? scoreToColor(category.score)
                            : "#E5E5E5",
                      }}
                    />
                  ))}
                </div>
              </div>
              <div
                style={{
                  marginLeft: "20px",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                {category.score} | 10
              </div>
            </div>
          ))}
        </div>
        <p
          style={{
            fontSize: "18px",
            margin: 0,
            marginTop: "20px",
            padding: "0 40px",
          }}
        >
          {dealMemo.overallEvaluation}
        </p>
      </div>
      <div style={{ marginTop: "auto" }}>
        <Footer textColor="#000" pageNumber={3} />
      </div>
    </div>
  );
};

const ReportPage = ({
  dateCreated,
  companyName,
  type,
  score,
  description,
  evaluation,
  pageNumber,
}) => {
  return (
    <div className="deal-memo-report">
      <div className="deal-memo-report__content">
        <div className="deal-memo-report__header">
          <div className="deal-memo-report__title-section">
            <p className="deal-memo-report__title">Investment Memo</p>
            <p className="deal-memo-report__subtitle">
              POWERED BY{" "}
              <span style={{ fontWeight: "bold" }}>DILIGENCEGPT v1.1</span>
            </p>
          </div>
          <div className="deal-memo-report__logo-section">
            <img
              src={logo}
              alt="StartupFuel Logo"
              className="deal-memo-report__logo"
            />
            <p className="deal-memo-report__agency">Venture Ratings Agency</p>
          </div>
        </div>
        <div className="deal-memo-report__company-section">
          <p className="deal-memo-report__company-name">{companyName}</p>
          <p className="deal-memo-report__date">Date-created: {dateCreated}</p>
        </div>
        <hr className="deal-memo-report__divider" />
        <div className="deal-memo-report__type-section">
          <p className="deal-memo-report__type-text">Equity Report</p>
        </div>
        <div className="deal-memo-report__main-content">
          <h2>{type}</h2>
          <div className="deal-memo-report__score-section">
            <div className="deal-memo-report__score-label">Company</div>
            <div className="deal-memo-report__score-bar">
              <div className="deal-memo-report__score-boxes">
                {[...Array(10)].map((_, i) => (
                  <div
                    key={i}
                    style={{
                      width: "40px",
                      height: "20px",
                      backgroundColor:
                        i < score ? scoreToColor(score) : "#E5E5E5",
                    }}
                  />
                ))}
              </div>
            </div>
            <div className="deal-memo-report__score-value">{score} | 10</div>
          </div>
          <p className="deal-memo-report__description">{description}</p>
          <hr className="deal-memo-report__divider" />
          <p className="deal-memo-report__evaluation-title">Evaluation</p>
          <p className="deal-memo-report__evaluation-text">{evaluation}</p>
        </div>
      </div>
      <Footer textColor="#000" pageNumber={pageNumber} />
    </div>
  );
};

const CheckoutForm = ({ onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);
    setError(null);

    try {
      const { error: submitError, paymentIntent } = await stripe.confirmPayment(
        {
          elements,
          redirect: "if_required",
        },
      );

      if (submitError) {
        setError(submitError.message);
      } else if (paymentIntent.status === "succeeded") {
        onSuccess();
      }
    } catch (err) {
      setError("An unexpected error occurred.");
      console.error(err);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div
        style={{
          padding: "20px",
          borderRadius: "8px",
          border: "1px solid #ddd",
          backgroundColor: "#fff",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        }}
      >
        <PaymentElement />
        {error && <div className="error-message">{error}</div>}
        <button
          style={{
            backgroundColor: "white",
            color: "black",
            padding: "10px 20px",
            borderRadius: "4px",
            border: "1px solid #ddd",
            cursor: "pointer",
            marginTop: "15px",
            width: "100%",
          }}
          disabled={!stripe || isProcessing}
        >
          {isProcessing ? "Processing..." : "Pay Now"}
        </button>
      </div>
    </form>
  );
};

const DealMemo = ({
  companyInfo,
  applicationObject,
  opportunityInfo,
  handleCreditChange,
  updateCompanyData,
}) => {
  const [dealMemos, setDealMemos] = useState(
    (
      companyInfo?.aiGenerations?.dealMemo.filter(
        (memo) => memo.dealMemo.status === "published",
      ) || []
    ).reverse(),
  );
  const [selectedMemoIndex, setSelectedMemoIndex] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [downloadingMemo, setDownloadingMemo] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const pdfRef = useRef(null);
  const [expandedMemos, setExpandedMemos] = useState({});
  const pdfRefs = useRef({});
  const [downloadingIndex, setDownloadingIndex] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [showCheckout, setShowCheckout] = useState(false);
  const [status, setStatus] = useState(
    companyInfo?.reports_in_progress?.deal_memo ? "in_progress" : "pending",
  );

  const handlePurchaseClick = async () => {
    try {
      setStatus("processing");
      setError(null);

      const response = await axios.post("/create_payment_intent", {
        companyId: companyInfo.applicant_company,
        opportunityId: opportunityInfo._id,
        productType: "dealMemo",
      });

      if (response.data.clientSecret) {
        setClientSecret(response.data.clientSecret);
        setShowCheckout(true);
      } else {
        throw new Error("No client secret received");
      }
    } catch (err) {
      console.error("Error:", err);
      setError(err.response?.data?.message || "Failed to initialize payment");
      setStatus("failed");
    }
  };

  const handlePaymentSuccess = () => {
    setShowCheckout(false);
    setStatus("in_progress");
  };

  const handleCloseCheckout = () => {
    setShowCheckout(false);
    setStatus("pending"); // Reset status when closing checkout
  };

  const downloadPdf = async (index) => {
    setDownloadingIndex(index);
    if (!expandedMemos[index]) {
      const newExpandedState = { ...expandedMemos };
      newExpandedState[index] = true;
      setExpandedMemos(newExpandedState);
      await new Promise((resolve) => setTimeout(resolve, 300));
    }

    const element = pdfRefs.current[index];
    if (!element) {
      setDownloadingIndex(null);
      return;
    }

    const options = {
      filename: `${companyInfo?.company_name} Deal Memo | StartupFuel.pdf`,
      html2canvas: {
        dpi: 192,
        letterRendering: true,
        scale: 2,
        useCORS: true,
        logging: true,
        removeContainer: true,
        height: element.clientHeight,
        windowHeight: element.clientHeight,
      },
      jsPDF: {
        orientation: "portrait",
        unit: "mm",
        format: "a4",
        hotfixes: ["px_scaling"],
        margin: [0, 0, 0, 0],
        compress: true,
      },
    };

    try {
      await html2pdf().from(element).set(options).save();
    } catch (error) {
      console.error("Error downloading PDF:", error);
      setError("Failed to download PDF. Please try again.");
    }
    setDownloadingIndex(null);
  };

  const handleDelete = async (memoId) => {
    setIsDeleting(true);
    try {
      await axios.delete(`/api/deal-memo/${memoId}`);
      setDealMemos(dealMemos.filter((memo) => memo._id !== memoId));
      setShowDeleteConfirm(null);
    } catch (error) {
      console.error("Error deleting memo:", error);
      setError("Failed to delete memo. Please try again.");
    } finally {
      setIsDeleting(false);
    }
  };

  const renderContent = () => {
    if (showCheckout && clientSecret) {
      return (
        <FullScreenModal show={showCheckout}>
          <div style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '2rem'
          }}>
            <button 
              onClick={handleCloseCheckout}
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                background: 'none',
                border: 'none',
                fontSize: '20px',
                cursor: 'pointer'
              }}
            >
              ×
            </button>
            <div style={{width: '100%', maxWidth: '500px', margin: '0 auto'}}>
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <CheckoutForm onSuccess={handlePaymentSuccess} />
              </Elements>
            </div>
          </div>
        </FullScreenModal>
      );
    }

    return (
      <div className="deal-memo-actions">
        {error && <div className="error-message">{error}</div>}

        <button
          className={`summarize-button ${status === "processing" || status === "in_progress" ? "disabled" : ""}`}
          onClick={
            status === "completed" ? () => downloadPdf(0) : handlePurchaseClick
          }
          disabled={status === "processing" || status === "in_progress"}
        >
          {getButtonText()}
        </button>

        {status === "in_progress" && (
          <div className="progress-message">
            Generating your deal memo... This may take a few minutes.
          </div>
        )}
      </div>
    );
  };

  const getButtonText = () => {
    switch (status) {
      case "pending":
        return "Purchase Deal Memo";
      case "processing":
        return "Processing Payment...";
      case "in_progress":
        return "Generating Deal Memo...";
      case "completed":
        return "Download Deal Memo";
      case "failed":
        return "Retry Purchase";
      default:
        return "Purchase Deal Memo";
    }
  };

  return (
    <div className="px-3">
      <h4>Deal Memo</h4>
      <p className="text-grey text-sm">
        Purchase a comprehensive deal memo report for your investment
        opportunity.
      </p>

      {renderContent()}

      {dealMemos.map((dealMemo, index) => (
        <div className="report-container" key={index}>
          <div
            className="overall-summary"
            onClick={() => {
              const newExpandedState = { ...expandedMemos };
              newExpandedState[index] = !newExpandedState[index];
              setExpandedMemos(newExpandedState);
            }}
          >
            <h2 className="overall-score">
              Deal Memo {dealMemos.length - index}
              {expandedMemos[index] ? <ExpandLess /> : <ExpandMore />}
            </h2>
            <div className="score-details-right">
              <p className="created-on">
                Date Created:{" "}
                {new Date(dealMemo.created_on).toLocaleDateString()}
              </p>
              <div className="score-button-container">
                <LoadingButton
                  className="button button-secondary"
                  onClick={(e) => {
                    e.stopPropagation();
                    downloadPdf(index);
                  }}
                  loading={downloadingIndex === index}
                  text={
                    downloadingIndex === index
                      ? "Downloading..."
                      : "Download PDF"
                  }
                />
                <button
                  className="button button-danger ml-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowDeleteConfirm(dealMemo._id);
                  }}
                >
                  <Delete />
                </button>
              </div>
              {showDeleteConfirm === dealMemo._id && (
                <div style={{ textAlign: "left" }}>
                  <p>Are you sure you want to delete this deal memo?</p>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <LoadingButton
                      className="button button-danger mr-2"
                      onClick={() => handleDelete(dealMemo._id)}
                      loading={isDeleting}
                      text="Delete"
                    />
                    <button
                      className="button button-secondary"
                      onClick={() => setShowDeleteConfirm(null)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>

          {expandedMemos[index] && (
            <div
              ref={(el) => (pdfRefs.current[index] = el)}
              style={{ fontFamily: "Montserrat" }}
            >
              {/* Page 1 */}
              <div
                style={{
                  width: "210mm",
                  height: "297mm",
                  padding: "20mm",
                  background: `url(${dealmemo_cover}) no-repeat center center/cover`,
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    bottom: "15mm",
                    right: "15mm",
                    fontSize: "20px",
                    color: "#F3F5F9",
                  }}
                >
                  {companyInfo?.company_name}
                </div>
              </div>

              {/* Page 2 */}
              <div
                style={{
                  width: "210mm",
                  height: "297mm",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#fff",
                }}
              >
                <div className="deal-memo-process">
                  <div className="deal-memo-process__header">
                    <div className="deal-memo-process__dots">
                      <div className="deal-memo-process__dot"></div>
                      <div className="deal-memo-process__dot"></div>
                      <div className="deal-memo-process__dot"></div>
                    </div>
                  </div>

                  <div className="deal-memo-process">
                    <div className="deal-memo-process__title-section">
                      <h1 className="deal-memo-process__title">Our Process</h1>
                      <div className="deal-memo-process__lines">
                        <div className="deal-memo-process__line deal-memo-process__line--large"></div>
                        <div className="deal-memo-process__line deal-memo-process__line--medium"></div>
                        <div className="deal-memo-process__line deal-memo-process__line--small"></div>
                      </div>
                    </div>

                    <div className="deal-memo-process__divider" />

                    <div className="deal-memo-process__content">
                      <div className="deal-memo-process__step">
                        <div className="deal-memo-process__step-number">01</div>
                        <div className="deal-memo-process__step-text">
                          <span style={{ fontWeight: "bold" }}>
                            Data Collection:
                          </span>{" "}
                          our AI powered software system connects to the
                          entrepreneur and asks them to submit business
                          documents to our PitchBox software or manual team
                          members.
                        </div>
                      </div>

                      <div className="deal-memo-process__step">
                        <div className="deal-memo-process__step-number">02</div>
                        <div className="deal-memo-process__step-text">
                          <span style={{ fontWeight: "bold" }}>Reviewing:</span>{" "}
                          our trained team of venture analysts begin researching
                          and building individual Critical Factor Assessments on
                          various areas of a the startup business.
                        </div>
                      </div>

                      <div className="deal-memo-process__step">
                        <div className="deal-memo-process__step-number">03</div>
                        <div className="deal-memo-process__step-text">
                          <span style={{ fontWeight: "bold" }}>Scoring:</span> 5
                          areas of the startup business are given a consensus
                          grade from the following 5 categories: Idea
                          (Problem/Market Fit, Product/Service, Marketing &
                          Sales, Team & Talent, Current Traction).
                        </div>
                      </div>

                      <div className="deal-memo-process__contact">
                        <p className="deal-memo-process__contact-text">
                          Contact us for information of how this report was
                          compiled
                        </p>
                        <p className="deal-memo-process__email">
                          diligence@startupfuel.com
                        </p>
                      </div>
                    </div>
                    <Footer textColor="#fff" pageNumber={2} />
                  </div>
                </div>
              </div>

              {/* Page 3 */}
              <div
                style={{
                  width: "210mm",
                  height: "297mm",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#fff",
                }}
              >
                <ReportOverview
                  dealMemo={dealMemo.dealMemo}
                  dateCreated={new Date(dealMemo.created_on).toLocaleDateString(
                    "en-US",
                    {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    },
                  )}
                />
              </div>

              {/* Report Pages */}
              {dealMemo.dealMemo?.categories?.map((category, categoryIndex) => (
                <div
                  key={category.name}
                  style={{
                    width: "210mm",
                    height: "297mm",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "#fff",
                  }}
                >
                  <ReportPage
                    companyName={companyInfo?.company_name}
                    type={category.name}
                    score={category.score}
                    description={category.description}
                    dateCreated={new Date(
                      dealMemo.created_on,
                    ).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                    evaluation={category.evaluation}
                    pageNumber={categoryIndex + 4}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default DealMemo;
